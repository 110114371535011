<template>
  <ul :class="['this-or-that-results', 'list-reset', { 'this-or-that-results--fill-bars': fillBars }]">
    <li
      v-for="(option, index) in options"
      :key="`this-or-that-results--${index}`"
      class="this-or-that-results__item"
    >
      <vote-count
        :class="{ 'vote-count--is-voted': isVoted(option.id) }"
        :answer="option.name"
        :votes="parseInt(option.totalVotes, 10)"
        :total="totalVotes"
      />
    </li>
  </ul>
</template>

<script>
import VoteCount from '@/components/Poll/VoteCount.vue';

export default {
  name: 'ThisOrThatResults',
  components: {
    VoteCount,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    votedAnswers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fillBars: false,
    };
  },
  computed: {
    totalVotes() {
      const options = this.options.map((option) => option.totalVotes);
      return options.reduce((accumulator, option) => accumulator + option);
    },
  },
  mounted() {
    this.fillBars = true;
  },
  methods: {
    isVoted(answerId) {
      const stringifyAnswerId = answerId.toString();
      return this.votedAnswers.includes(stringifyAnswerId);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_this-or-that-results';
</style>
