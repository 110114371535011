<template>
  <form-component
    class="this-or-that-form"
    @submit.prevent="handleFormSubmit"
  >
    <button-component
      v-for="(option, index) in options"
      :key="`this-or-that-form--${index}`"
      class="this-or-that-form__option"
      type="select"
      @click="handleOptionSelect(option.id)"
    >
      {{ option.name }}
    </button-component>
  </form-component>
</template>

<script>
import ButtonComponent from '@/components/Global/ButtonComponent.vue';
import { ENGAGEMENT_VOTE } from '@/graphql/mutations/engagement-toolkit-mutations';

export default {
  name: 'ThisOrThatForm',
  components: {
    ButtonComponent,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    formId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedOptionId: [],
    };
  },
  methods: {
    handleOptionSelect(id) {
      this.selectedOptionId.push(id);
    },
    handleFormSubmit() {
      this.$apollo.mutate({
        mutation: ENGAGEMENT_VOTE,
        variables: {
          engagementId: this.formId,
          optionIds: this.selectedOptionId,
        },
      }).then(() => {
        this.$emit('optionSelected');
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_this-or-that-form';
</style>
